<template>
<div>
  <b-card
    title="标签名称"
  >
      <b-row>
        <b-col cols="4">
        <b-form-group
          label="标签名"
          label-for="basicInput"
        >
          <b-form-input
            :disabled="!edit"
            id="basicInput"
            v-model="tag.name"
          />
        </b-form-group>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="!edit"
          @click="saveTag"
          v-if="$checkButtonPermission('201001001')"
          >
          保存
        </b-button>
      <b-button
        variant="outline-secondary"
        type="reset"
        @click="back"
      >
        取消
      </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      title="设备列表"
    >
    <b-button
      class="mb-2 mr-2"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :disabled="!edit"
      variant="primary"
      @click="removeDevices"
      v-if="$checkButtonPermission('201001001')"
    >
      移除
    </b-button>
      <!-- table -->
    <vue-good-table
        ref="deviceTable"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        :pagination-options="{
          enabled: true,
          perPage:pageSize
        }"
        theme="my-theme"
        @on-row-click="onRowClick"
        @on-cell-click="onCellClick"
        @on-select-all="onSelectAll"
        @on-row-dblclick="onRowDoubleClick"
      >

      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'deviceStatus'">
          {{getDeviceStatusDesc(props.row.deviceStatus)}}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="tCenter">
              <b-pagination
                :total-rows="total"
                :per-page="pageSize"
                v-model="currentPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="currentPageHandleChange"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BCard,BFormTextarea,BFormRadioGroup,
  BPagination,BAvatar,BDropdown,BDropdownItem,BToast,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import request from '@/api/request'
export default {
  components: {
    BCard,BFormRadioGroup,VueGoodTable,BPagination,BAvatar,BDropdown,BDropdownItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    vSelect,
    BToast,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      tag: {
        name: ""
      },
      edit: this.$route.params.edit,
      currentPage: 1,
      pageSize: 10,
      total:0,
      dir: false,
      columns: [
        {
          label: '软设备ID',
          field: 'configId'
        },
        {
          label: '设备SN',
          field: 'deviceId',
        },
        {
          label: '产品名称',
          field: 'productName',
        },
        {
          label: '设备状态',
          field: 'deviceStatus',
        }
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    console.log('HAHA')
    this.getTagInfo()
    this.pageDevice()
  },
  methods:{
    back(){
      this.$router.back()
    },
    removeDevices(){
      let deviceList = this.$refs['deviceTable'].selectedRows
      if(deviceList.length <= 0){
        this.$bvToast.toast("请选择要删除的关联设备", {
          title: `提醒`,
          variant: 'danger',
          solid: true
        })
        return
      }
      let deviceIdList = []
      for(let i=0;i<deviceList.length;i++){
        deviceIdList.push(deviceList[i].id)
      }
      if(deviceIdList.length > 0){
        request.delete('tob/tag/bTagDevice/delete',{idList: deviceIdList}).then(res => {
          if (res.data.success) {
            this.$bvToast.toast("操作成功",{
              title: `提示`,
              variant: 'success',
              solid: true
            })
            this.pageDevice(true)
          }
        })
      }
    },
    saveTag(){
      if(!this.tag.name || this.tag.name.length == 0){
        this.$bvToast.toast("标签名称不能为空", {
          title: `提醒`,
          variant: 'danger',
          solid: true
        })
        return
      }
      if(this.tag.name && this.tag.name.length > 6){
        this.$bvToast.toast("标签名称过长，请低于6位字符", {
          title: `提醒`,
          variant: 'danger',
          solid: true
        })
        return
      }
      request.post('tob/tag/bTag/save',{id: this.tag.id, name:this.tag.name, note: this.tag.note}).then(res => {
        if (res.data.success) {
          this.$bvToast.toast("操作成功",{
            title: `提示`,
            variant: 'success',
            solid: true
          })
          this.getTagInfo()
        }
      })
    },
    onRowClick(params) {
      console.log(params.row)
    },
    onSelectAll(params){
      console.log('all')
    },
    onRowDoubleClick(params) {
      console.log(params)
    },
    onCellClick(params) {
      if(params.column.field == 'action'){
        console.log(params.row)
      }
    },
    getTagInfo(){
      request.get('tob/tag/bTag/info', {tagId:this.id}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.tag = res.data.data
        }
      })
    },
    pageDevice(resetCurrentPage){
      if(resetCurrentPage)
        this.currentPage = 1
      request.get('tob/tag/bTagDevice/page', {tagId:this.id, currentPage: this.currentPage, pageSize: this.pageSize}).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('total',this.total)
        }
      })
    },
    getDeviceStatusDesc(status){
      switch(status){
        case 0:
          return "未激活"
        case 1:
          return "离线"
        case 2:
          return "在线"
        case 3:
          return "已激活"
      }
    },
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pageDevice()
    },
  },
  watch:{
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
