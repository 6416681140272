var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"title":"标签名称"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":"标签名","label-for":"basicInput"}},[_c('b-form-input',{attrs:{"disabled":!_vm.edit,"id":"basicInput"},model:{value:(_vm.tag.name),callback:function ($$v) {_vm.$set(_vm.tag, "name", $$v)},expression:"tag.name"}})],1),(_vm.$checkButtonPermission('201001001'))?_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","disabled":!_vm.edit},on:{"click":_vm.saveTag}},[_vm._v(" 保存 ")]):_vm._e(),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":_vm.back}},[_vm._v(" 取消 ")])],1)],1)],1),_c('b-card',{attrs:{"title":"设备列表"}},[(_vm.$checkButtonPermission('201001001'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-2 mr-2",attrs:{"disabled":!_vm.edit,"variant":"primary"},on:{"click":_vm.removeDevices}},[_vm._v(" 移除 ")]):_vm._e(),_c('vue-good-table',{ref:"deviceTable",attrs:{"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.direction,"sort-options":{
          enabled: false,
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        },"pagination-options":{
          enabled: true,
          perPage:_vm.pageSize
        },"theme":"my-theme"},on:{"on-row-click":_vm.onRowClick,"on-cell-click":_vm.onCellClick,"on-select-all":_vm.onSelectAll,"on-row-dblclick":_vm.onRowDoubleClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'deviceStatus')?_c('span',[_vm._v(" "+_vm._s(_vm.getDeviceStatusDesc(props.row.deviceStatus))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"tCenter"},[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"total-rows":_vm.total,"per-page":_vm.pageSize,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.currentPageHandleChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }